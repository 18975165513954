import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component'
import  'bootstrap/dist/css/bootstrap.min.css';
import banner from '../components/images/banner-academia.jpg'
// const Footer = loadable(() => import('../components/Footer'))
// const Nav = loadable(() => import ('../components/Nav'))
import Footer  from "../components/Footer";
import Nav from "../components/Nav";
import AcademiaForm from '../components/Forms/AcademiaForm'


// const TopNav = loadable(() => import ('../components/TopNav'))
   
function Academia()  {
  return (
    <>

      {/* <TopNav/> */}
      <Nav/>
      <section className="min-height-400px cover-background top-space-verum" style={{backgroundImage: `url(${banner})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 wow fadeInLeft">
                        <h3 className="inner-banner-title text-white">Verum's Academic Program</h3>
                  </div> 
                    <div className="col-12 col-lg-4 wow fadeInRight" data-wow-delay="0.2s"></div>
                </div>
            </div>
        </section>
        <section className="padding-70px-tb">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 md-margin-50px-bottom sm-margin-30px-bottom">
                        <h5 className="title-section-alt alt-font">Academic program</h5>
                        <p>Verum's Academic Program offers students resources and software to become familiar with Dezyne, our unique Model Driven Software Engineering toolset applied to embedded, industrial & cyber-physical software systems. Using Dezyne, students will acquire skills to specify and model the software from which the integration ready code (C, C++, C#) will be generated.</p>
                        <p>Are you interested in the Verum's Academic Program? Just let us know by filling in the form, and we will contact you as soon as possible.</p>
                        <h6 className="title-section-alt alt-font">Student competitions</h6>
                        <p>We offer student team sponsorships. Verum will give student teams an extra edge in competitions by sharing its knowledge of and experience in embedded software systems engineering and by providing them with its unique software toolset, Dezyne.</p>
                        <p>
                        	<iframe title="Tim Beurskens, graduate student Embedded Systems at the Eindhoven University of Technology." width="100%" height="310" src="https://www.youtube.com/embed/KT3jgaG8d00?&amp;feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </p>
                    </div>
            <AcademiaForm/>
            </div>                               
            </div>
        </section>
      <Footer/>
    </>
 );
}

export default Academia;
